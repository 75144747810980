<template>
  <div class="bx">
    一、站酷用户协议的接受
    当您在注册程序过程中在“已阅读，同意本《用户协议》”处打勾“
    一、站酷用户协议的接受
    当您在注册程序过程中在“已阅读，同意本《用户协议》”处打勾“
    一、站酷用户协议的接受
    当您在注册程序过程中在“已阅读，同意本《用户协议》”处打勾“
    一、站酷用户协议的接受
    当您在注册程序过程中在“已阅读，同意本《用户协议》”处打勾“
  </div>
</template>
