import axios from 'axios';
import config from '../_config';
import {
  Toast
} from 'vant';

export function downloadApp() {
  var u = navigator.userAgent;
  var isWeixin = u.toLowerCase().indexOf('micromessenger') !== -1; // 微信内
  var isComWx = /wxwork/i.test(u); // 是否 企业微信
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; // android终端
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  console.log(u, isWeixin, isComWx, isAndroid, isIOS);
  let flag = false;
  if (isComWx || isWeixin) {
    flag = true;
    return flag;
  }
  // TODO 请求下载地址
  if (isAndroid) {
    // 安卓跳转
    const url = `app/versionInfo/androidDownloadAddress`;
    let appFileUrl = '';
    axios.get(config.postDetailUrl + url).then(res => {
      // console.log(res);
      if (res && res.data?.code === 200) {
        appFileUrl = res.data.data.appFileUrl;
        window.open(appFileUrl);
      } else {
        Toast(res.data?.message || '网络连接异常,请稍后再试!');
      }
    });
  }
  if (isIOS) {
    const url = 'https://apps.apple.com/cn/app/%E4%B8%8A%E8%BF%9B%E9%9D%92%E5%B9%B4-%E8%B7%91%E6%AD%A5%E8%AF%BB%E4%B9%A6%E4%B8%8A%E8%BF%9B%E4%B9%A0%E6%83%AF%E5%85%BB%E6%88%90%E7%A4%BE%E5%8C%BA/id1639250598';
    window.open(url);
  }
  return flag;
}
